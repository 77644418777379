import React from 'react'
import './Header.css'
const Header = () => {
  return (
    <div className='bg-primary text-white header'>
      My React App
    </div>
  )
}

export default Header
