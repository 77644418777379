export const menuItems=[
  {
    name: "Home",
    link: "/home"
    },
  {
      name: "About",
      link: "/about"
    },
  {
        name: "Contact",
        link: "/contact"
    },
]